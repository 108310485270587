import React from 'react';
import "./GetStarted.css";

const GetStarted = () => {
  return (
   <section className='g-wrapper'>
    <div className='paddings innerWidth g-container'>
        <div className='flexColCenter inner-container'>
            <span className='darkline'>Get Started with Real Estate</span>
            <span className='orangeText'>Suscribe and find the attractive quotes from us <br/>
            Find your property soon</span>
            <button className='button'>
                <a href='suryanshgangwar78@gmail.com'>Mail </a>
            </button>
        </div>
    </div>
   </section>
  )
}

export default GetStarted;
