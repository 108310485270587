import React from 'react'
import './Footer.css';

const Footer = () => {
  return (
    <section className='f-wrapper'>
        <div className='paddings innerWidth flexCenter f-container'>
            {/*left side*/}
            <div className='flexColStart f-left'>
                <img src='logo.png' alt = "Oops" width = {120}/>
                <span className='secondaryText'>
                "Transforming Spaces into Places You'll Love to Call Home: Discover the Perfect Property with Us Today!"
                </span>
            </div>
            {/*right side*/}
            <div className='flexColStart f-right'>
              <span className='primaryText'>
                Information
              </span>
              <span className='secondaryText'>
                Agra, UttarPradesh
              </span>
              <div className='flexCenter f-menu'>
                <span>Property</span>
                <span>Services</span>
                <span>Product</span>
                <span>About Us</span>
              </div>
            </div>
        </div>
    </section>
  )
}

export default Footer
