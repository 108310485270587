import React from 'react'
import "./Contact.css";
import {MdCall} from 'react-icons/md';
import {HiChatBubbleBottomCenter} from 'react-icons/hi2';
import {BsFillChatDotsFill} from "react-icons/bs";

const Contact = () => {
  return (
   <section className='c-wrapper'>
    <div className='paddings innerWidth flexCenter c-container'>
        <div className='flexColStart c-left'>
            <span className='orangeText'>Our Contacts</span>
            <span className='primaryText'>Easy to contact us</span>
            <span className='secondaryText'>
                hello myself Suryansh Gangwar, Electrical and Electronics Eng ,Nit Andhra Pradesh

            </span>
            <div className="flexColStart contactModes">
            {/* first row */}
            <div className="flexStart row">
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    <MdCall size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Call</span>
                    <span className="secondaryText">021 123 145 14</span>
                  </div>
                </div>
                <div className="flexCenter button">Call now</div>
              </div>

              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    <BsFillChatDotsFill size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Chat</span>
                    <span className="secondaryText">021 123 145 14</span>
                  </div>
                </div>
                <div className="flexCenter button">Chat now</div>
              </div>
            </div>

            {/* second row */}
            <div className="flexStart row">
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    <BsFillChatDotsFill size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Video Call</span>
                    <span className="secondaryText">021 123 145 14</span>
                  </div>
                </div>
                <div className="flexCenter button">Video Call now</div>
              </div>

              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    <HiChatBubbleBottomCenter size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Message</span>
                    <span className="secondaryText">021 123 145 14</span>
                  </div>
                </div>
                <div className="flexCenter button">Message now</div>
              </div>
            </div>
          </div>
        </div>
        <div className='c-right'>
            <div className='image-container'>
                <img src='./main4.jpg' alt = ""/>
            </div>
        </div>
    </div>
   </section>
  )
}

export default Contact
